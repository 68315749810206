import * as React from 'react';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
const Tnode = ({ text , orientation }) => {
  return (
       <TimelineItem>
        <TimelineSeparator>
          <TimelineDot>
            <div className="z-40 bg-slate-50 2xl:w-10 2xl:h-10 rounded-full"></div>
            </TimelineDot>
          
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className='z-40'>
            
            <div className={` transition-all duration-700 border 2xl:w-1/2 md:w-1/2 xs:w-full my-auto 2xl:p-24 2xl:text-3xl md:text-2xl xs:text-lg bg-slate-50 hover:shadow-2xl hover:rounded-3xl text-center ${orientation}`}>
            <div className='z-10 absolute   top-5    2xl:w-5 2xl:h-5 bg-slate-500   rounded-full rotate-45'></div>
{text}
            </div>
           
            </TimelineContent>
      </TimelineItem>
  );
}
export default Tnode