import Triangle from "./triangle";
import { Parallax } from 'react-scroll-parallax';




const ServiceIntro = ({theme}) => {
    function    scroll(e){
        console.log(e.target)
      e.target.setAttribute("stroke" , "green-500")
    }
  
    return (
        <Parallax translateY={[-50, 105]} opacity={[5, -2]}   >
      
<div className={ `z-20 flex flex-col  bg-black  text-center m-auto  h-96  p-5 bgservice_image  text-slate-100 `}  >
    


        <div className= {` text-slate-100 2xl:text-5xl md:text-7xl xs:text-3xl `} >The all in one Service <br></br>for</div> 
        <div className={`2xl:text-3xl md:text-5xl xs:text-2xl text-slate-100 `}>Machinery & Equipments</div> 

  
 { // <Triangle theme={theme} height={100} animate={true} onClick={scroll} />
}
</div>   
</Parallax>
);
    }
export default ServiceIntro;