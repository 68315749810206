import Json from "./fetchJson"
import { useState } from "react";
import MainService from "./mainservice";
import Triangle from "./triangle";
import ProjectCard from "./projectCard";

const  ProjectsFetcher = ({theme}) => {
      const Projects = Json({url: "../data/projects.json"})
   
 
    return(
        <div className={` ${theme.tailwind_bg_primary}  `} >
        <div className={` ${theme.tailwind_bg_primary} py-8 grid grid-cols-12 w-11/12 m-auto gap-2 `} >
           
            {
                Projects && Projects.map((project , key) => {
                    return(
                        <ProjectCard key={key} project={project } nummer={key} />
                    )
                })
            }
        </div>
        </div>
    )
}
export default ProjectsFetcher;