import Json from "./fetchJson"
import { useState } from "react";
import MainService from "./mainservice";
import Triangle from "./triangle";
import { Parallax } from "react-scroll-parallax";

const  MainServiceFetcher = ({theme}) => {
      const services = Json({url: "../data/services.json"})
    const [details, setDetails] = useState(true)

    
    return(
        
        <Parallax translateY={[50, -15]}  className={` `} >
        <div className={ `  flex-col h-screen  grid grid-cols-12     2xl:w-11/12 md:w-12/12 xs:w-12/12   mx-auto ${theme.tailwind_bg_primary} `} >
            {
                services && services[1]["major_services"].map((service) => {
                    return(
                        
<MainService service={service}  icon={<Triangle theme={theme} height={100} animate={false} deg={90} /> }/>
                       
                        
                    )
                })
            }
        </div></Parallax>
         
    )
}
export default MainServiceFetcher;