import SLogo from "./singleLogo";
import OLogo from "./Ologo";
const Slogan = ({theme}) => {
    return (
        
              <div className={`  p-3 flex  flex-col  w-full h-96 border-b-8    text-slate-50  xl:bg-[url('../public/img/engs.png')] bg-no-repeat bg-right xl:items-start  `} >
                

         
         <div className=" ">
<SLogo width={100} />

            <div className= { `  2xl:text-5xl  md:text-5xl xs:text-xl uppercase   font-thin    `} >
            
                <div className={ `  text-center  font-bold  tracking-tighter  `}>Advanced</div><div className={ `tracking-wider text-center  `} >Solutions</div> 
                </div>
                
            
                 
                <div className={" text-center  text-2xl     2xl:text-2xl md:text-xl xs:text-xl      tracking-wide  "} >
                   FOR MACHINERY AND EQUIPMENT CO. LTD. <br></br>
                    Your E, I & CS Systems Integration Partner 
                </div>
             
         </div>
 
        </div>)
}
export default Slogan;
        
