import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Tnode from './tnode';

export default function LeftPositionedTimeline({theme}) {
  return (
    <Timeline className='bg-slate-100 m-0'  position="alternate">
        <div className={`flex m-auto 2xl:text-5xl ${theme.tailwind_text_main}`}>
Our portfolio includes
        </div>
 <Tnode text="Upgrading emergency shutdown systems, instruments, and PLCs.." orientation="float-start"  />
 <Tnode text="Replacing service transformers, switchgear, and MCC buckets" orientation="float-end" />
 <Tnode text="Installing and enhancing protection systems, control stations, and electrical systems." orientation="float-start" />
 <Tnode text="Conducting comprehensive upgrades to motors, MCCs, and monitoring systems." orientation="float-end" />
      
    </Timeline>
  );
}
