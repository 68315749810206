import Slogan from "./slogan";
import EmployeeIcon from "./employeeicon";
import Triangle from "./triangle";
import Percentage from "./percentage";
import ProjectTask from "./projectTask";
import DiagramIcon from "./diagramIcon";
const ProjectsIntro = ({theme, auth}) => {
    return (
        
        <div id="intro" className={`z-30 w-12/12 m-auto flex flex-col   my-5 p-2  h-96     ${theme.tailwind_bg_primary} `}>
          <div className={ `text-5xl ${theme.tailwind_text_dark} font-black `}>Projects Portal</div>
       <div className="z-30    ">
         <div className="flex transition-shadow duration-700 hover:shadow-2xl  z-30 2xl:w-1/2 md:w-full xs:w-full     2xl:text-xl md:text-xl xs:text-2xl  font-thin    ">
        <div>Welcome to our Projects Portal! Here, we showcase a diverse array of engineering and construction projects undertaken across various industries.</div>
    
   
     <DiagramIcon theme={theme} height={50} />
     </  div>


<div className="z-40">
 
<div className=" transition-shadow duration-700 hover:shadow-2xl flex float-end 2xl:w-2/4  hover:bg-slate-50  text-xl font-Thin 2xl:mx-48 xs:my-2 mb-8 2xl:p-5 h-48 xs:m-auto items-center  ">

Each project is tailored to meet the specific needs of our clients, ensuring efficient, reliable, and state-of-the-art solutions. Browse through to explore our commitment to innovation and excellence in every project we undertake.
</div>
        </div> 
 
</div>



     
      

  </div>
    )
}
export default ProjectsIntro;